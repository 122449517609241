// Styles SCSS
import '../sass/app.scss';

// jQuery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Masonry layout
import Masonry from './utils/masonry';

// SVG polyfiil
import 'svgxuse';

// Flash Info
import 'jquery.marquee';

import datepicker from 'js-datepicker';

// Accordions
import Accordion from 'accordion-js';

//Tabs
import { Tabs } from './utils/tabs';

// pushbar
import Pushbar from './utils/pushbar';

// Lightbox
//import './utils/fancybox';

// LazyLoad
import lazyLoadInit from './lazyload-init';
import { visible } from 'ansi-colors';

lazyLoadInit();

$(document).ready(() => {
	// Gtranslate
	jQuery('.translate option:contains("Select Language")').text('langues');
	jQuery('.translate option:contains("English")').text('EN');
	jQuery('.translate option:contains("French")').text('FR');
	jQuery('.translate option:contains("Spanish")').text('ES');
	jQuery('.translate option:contains("FR")').attr('selected', true);

	$('.ticker').marquee({
		duration: 13000,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: false,
		pauseOnHover: true
	});

	if (document.querySelector('.accordion-container') !== null) {
		new Accordion('.accordion-container');
	}
	if (document.querySelector('.tabs-container') !== null) {
		new Tabs({
			elem: 'tabs',
			open: 0
		});
	}

	new Pushbar({
		blur: true,
		overlay: true
	});

	const grid = document.querySelector('.galerie');
	new Masonry(grid, {
		itemSelector: '.galerie__item',
		columnWidth: 325,
		gutter: 10
	});
});

// back to top button
if ($('#back-to-top').length) {
	var scrollTrigger = 100, // px
		backToTop = function() {
			var scrollTop = $(window).scrollTop();
			if (scrollTop > scrollTrigger) {
				$('#back-to-top').addClass('show');
			} else {
				$('#back-to-top').removeClass('show');
			}
		};
	backToTop();
	$(window).on('scroll', function() {
		backToTop();
	});
	$('#back-to-top').on('click', function(e) {
		e.preventDefault();
		$('html,body').animate(
			{
				scrollTop: 0
			},
			700
		);
	});
}
if (document.querySelector('.date-debut') !== null) {
	datepicker('.date-debut', {
		startDay: 1,
		customMonths: [
			'janvier',
			'février',
			'mars',
			'avril',
			'mai',
			'juin',
			'juillet',
			'août',
			'septembre',
			'octobre',
			'novembre',
			'décembre'
		],
		customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Vend', 'Sam'],
		formatter: (input, date, instance) => {
			const value = date.toLocaleDateString('fr-FR');
			input.value = value; // => '1/1/2099'
		}
	});
}
if (document.querySelector('.date-fin') !== null) {
	datepicker('.date-fin', {
		startDay: 1,
		customMonths: [
			'janvier',
			'février',
			'mars',
			'avril',
			'mai',
			'juin',
			'juillet',
			'août',
			'septembre',
			'octobre',
			'novembre',
			'décembre'
		],
		customDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Vend', 'Sam'],
		formatter: (input, date, instance) => {
			const value = date.toLocaleDateString('fr-FR');
			input.value = value; // => '1/1/2099'
		}

	});
}


/*      JQUERY				*/
$(document).ready(function(){

	/* ROLLOVER GENERIQUE */
	/* A am�liorer lorsqu'on aura Jquery 1.4 */
	/*
	Mise en place : mettre une class="rollover" sur le lien qui entoure l'image sur laquelle on veut le roll over
	Les images au repos sont nomm�es "monimage_off.png"
	Les images au survol sont nomm�es "monimage_on.png"
	Ex : <a href="www.google.fr" class="rollover"><img src="images/testrollover_off.png" alt="" /></a>
	Quand un item du menu est actif, ajouter la classe "actif" ainsi, et faire afficher l'image "_off", le roll over sera d�sactiv� :
	Ex : <a href="www.google.fr" class="rollover actif"><img src="images/testrollover_off.png" alt="" /></a>
	*/
	
	$('.rollover:not(.actif)').hover(
		function()
		{
			var img = $(this).find('img').attr('src');
			var newsrc = img.replace('_off','_on');
			$(this).find('img').attr('src',newsrc);
		},
		function()
		{
			var img = $(this).find('img').attr('src');
			var newsrc = img.replace('_on','_off');
			$(this).find('img').attr('src',newsrc);
		}
	);
	$('.rollover:not(.actif)').focus(
		function(){
			var img = $(this).find('img').attr('src');
			var newsrc = img.replace('_off','_on');
			$(this).find('img').attr('src',newsrc);
		}
	);
	$('.rollover:not(.actif)').blur(
		function(){
			var img = $(this).find('img').attr('src');
			var newsrc = img.replace('_on','_off');
			$(this).find('img').attr('src',newsrc);
		}
	);

});

// -----------------------------------------------------------------
//
//                      ROLL OVER
//
// -----------------------------------------------------------------
function newImage(arg) {
	if (document.images) {
		var rslt = new Image();
		rslt.src = arg;
		return rslt;
	}
}


function changeImages() {

	if (document.images && (preloadFlag == true)) {
	
		var img;
		for (var i=0; i<changeImages.arguments.length; i+=2) {
			img = null;
			if (document.layers) {
				img = findElement(changeImages.arguments[i],0);
			}
			else {
				img = document.images[changeImages.arguments[i]];
			}
			if (img) {
				img.src = changeImages.arguments[i+1];
			}
		}
	}
}

function closeall() {
	hide_couche_margin(1,'','.gif',0);
}

function MM_findObj(n, d) { //v4.01
	var p,i,x;  if(!d) d=document; if((p=n.indexOf('?'))>0&&parent.frames.length) {
		d=parent.frames[n.substring(p+1)].document; n=n.substring(0,p);}
	if(!(x=d[n])&&d.all) x=d.all[n]; for (i=0;!x&&i<d.forms.length;i++) x=d.forms[i][n];
	for(i=0;!x&&d.layers&&i<d.layers.length;i++) x=MM_findObj(n,d.layers[i].document);
	if(!x && d.getElementById) x=d.getElementById(n); return x;
}

function MM_showHideLayers() { //v6.0
	var i,p,v,obj,args=MM_showHideLayers.arguments;
	for (i=0; i<(args.length-2); i+=3) if ((obj=MM_findObj(args[i]))!=null) { v=args[i+2];
		if (obj.style) { obj=obj.style; v=(v=='show')?'visible':(v=='hide')?'hidden':v; }
		obj.visibility=v; }
}


// -----------------------------------------------------------------
//
//                      AFFICHER OU MASQUER UNE DIV
//
// -----------------------------------------------------------------
function visibilite(thingId)
{
	var targetElement;
	targetElement = document.getElementById(thingId) ;
	if (targetElement.style.display == 'none')
	{
		targetElement.style.display = '' ;
	} else {
		targetElement.style.display = 'none' ;
	}
}

